import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import MoreInformationSection, {
  MoreInformationSectionType,
} from 'components/organisms/more-information-section';
import OurMissionSection, { OurMissionSectionType } from 'components/organisms/our-mission-section';
import OurValuesSection, { OurValuesSectionType } from 'components/organisms/our-values-section';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  aboutMissionPage: {
    template: {
      aboutMissionData: {
        bannerPrimary: BannerPrimaryType;
        ourMissionAndVision: OurMissionSectionType;
        ourValues: OurValuesSectionType;
        moreInformationSection: MoreInformationSectionType;
      };
    };
  };
};

const AboutMissionPage = ({
  pageContext,
  data: {
    aboutMissionPage: {
      template: {
        aboutMissionData: {
          bannerPrimary: { heading, subHeading, image, text },
          ourMissionAndVision,
          ourValues,
          moreInformationSection,
        },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      showDots
      titleTop={heading}
      subtitleTop={subHeading}
      image={image}
      description={text}
      positionTitle="left"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <OurMissionSection
      {...ourMissionAndVision}
      isSmallTitle
      largePadding
      mobilePadding="4px 0 0 0"
      padding={`${theme.gap.xl} 0 0 0`}
    />
    <OurValuesSection
      {...ourValues}
      margin={`${theme.gap.xl}`}
      bgColor={`${theme.colors.grayscaleGray0}`}
    />
    <MoreInformationSection {...moreInformationSection} />
  </Layout>
);

export const query = graphql`
  query AboutMissionPage($id: String) {
    aboutMissionPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutMission {
          aboutMissionData {
            bannerPrimary {
              heading
              subHeading
              text
              image {
                ...WpImageFragment
              }
            }
            moreInformationSection {
              heading
              cardList {
                text
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      id
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
                icon {
                  name
                }
              }
            }
            ourMissionAndVision {
              firstSection: ourMission {
                heading
                subTitle
                text
                image {
                  ...WpImageFragment
                }
              }
              secondSection: ourVision {
                heading
                subTitle
                text
                image {
                  ...WpImageFragment
                }
              }
            }
            ourValues {
              heading
              text
              valuesList {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutMissionPage;
